<template>
    <div>
        <div class="d-flex justify-content-end">
            <b-button 
                @click="modalCreateQuestion()"
                variant="primary"
                class="btn waves-effect waves-float waves-light btn-primary mb-2">
                <!-- Add Modul <feather-icon icon="PlusIcon" size="16" /> -->
                Create Question
            </b-button>
            <b-button 
                @click="goBack()"
                variant="primary"
                class="btn waves-effect waves-float waves-light btn-primary mb-2 ml-1">
                Back
            </b-button>
        </div>

        <b-row>
            <b-col cols="6" v-for="(item, index) in question" :key="index">
                <b-card>
                    <b-row>
                        <b-col cols="12" sm="7" md="9">
                            <h3 class="mb-2">{{ item.text }} #{{ item.order }}</h3>
                        </b-col>
                        <b-col cols="6" sm="5" md="3" class="d-flex justify-content-end h-50">
                            <b-dropdown size="sm" variant="outline-primary">
                                <template #button-content> 
                                    <feather-icon icon="Edit3Icon" size="16" /> 
                                </template>
                                <div>
                                <b-dropdown-item
                                    @click="editItem(item)"
                                >
                                    <feather-icon icon="EditIcon" class="mr-75" />
                                    Update
                                </b-dropdown-item>
                                </div>
                                <div>
                                <b-dropdown-item
                                    @click="deleteItem(item.uuid)"
                                >
                                    <feather-icon icon="TrashIcon" class="mr-75" />
                                    Delete
                                </b-dropdown-item>
                                </div>
                            </b-dropdown>
                        </b-col>
                    </b-row>
                    <div class="font-weight-bold text-capitalize" v-if="item.file !== null && item.type === 'task'">
                        {{ item.type }} - <a :href="item.file">Link PPT</a>
                    </div>
                    <div v-for="(answer, index) in item.answers" :key="index">
                        <b-row>
                            <b-col cols="12">
                                <div class="font-weight-bold">
                                    {{ index + 1 }}. {{ answer.text }}
                                </div>
                                <div class="text-capitalize text-muted">
                                    {{ answer.is_correct_answer == true ? 'Correct' : 'Incorrect' }}
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-col>
        </b-row>

        <b-modal 
            id="form-question-modal" 
            centered 
            :title="editId != null ? 'Edit Question' : 'Add Question'" 
            size="lg"
            no-close-on-backdrop hide-footer
        >
            <ValidationObserver>
                <div class="form">
                    <div class="form-group">
                        <label>Question:</label>
                        <validation-provider 
                            name="name" 
                            rules="required" 
                        >
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="formPayload.text"
                                placeholder="Question" 
                            />
                            <small 
                                v-for="(validation, index) in validations.text" 
                                :key="`errorName${index}`"
                                class="text-danger">{{ validation }}</small
                            >
                        </validation-provider>
                    </div>
                    <div class="form-group">
                        <label>Type:</label>
                        <validation-provider 
                            name="name" 
                            rules="required" 
                        >
                            <v-select
                                id="type"
                                label="title"
                                v-model="formPayload.type"
                                :options="optionType"
                                placeholder="-- Pilih --"
                                :reduce="(option) => option.value"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            />
                            <small 
                                v-for="(validation, index) in validations.type" 
                                :key="`errorName${index}`"
                                class="text-danger">{{ validation }}</small
                            >
                        </validation-provider>
                    </div>
                    <div class="form-group">
                        <label>Order:</label>
                        <validation-provider 
                            name="name" 
                            rules="required" 
                        >
                            <input 
                                type="number" 
                                class="form-control" 
                                v-model="formPayload.order"
                                placeholder="Order" 
                            />
                            <small 
                                v-for="(validation, index) in validations.order" 
                                :key="`errorName${index}`"
                                class="text-danger">{{ validation }}</small
                            >
                        </validation-provider>
                    </div>
                    <div class="form-group" v-if="is_task">
                        <label for="">File PPT:</label><br />
                        <!-- <p class="image__hint"><i>Max 5 mb</i></p> -->
                        <validation-provider name="image" rules="required">
                            <b-form-file
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            @change="changeFile($event)"
                            />
                            <small v-if="file_url !== null">Current file:</small><br />
                            <a :href="file_url" v-if="(file_url !== null && file_type == 'ppt' || file_type == 'pptx')">
                                <small>Link PPT</small>
                            </a>
                            <!-- <iframe 
                                v-if="(file_url !== null && file_type == 'ppt' || file_type == 'pptx' || file_type == 'vnd.openxmlformats-officedocument.presentationml.presentation')"
                                id="file"
                                class="media_file" 
                                controls 
                                :src="file_url">
                            </iframe> -->
                            <small class="text-danger">{{ validationsFile }}</small>
                        </validation-provider>
                    </div>
                    <div v-if="!is_task && !is_essay">
                        <div class="form-group">
                            <label>Answer #1:</label>
                            <validation-provider 
                                name="name" 
                                rules="required" 
                            >
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="formPayload.answers[0].text"
                                    placeholder="Answer" 
                                />
                                <small 
                                    v-for="(validation, index) in validations['answers.0.text']" 
                                    :key="`errorName${index}`"
                                    class="text-danger">{{ validation }}</small
                                >
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <label>Is Correct Answer #1:</label>
                            <validation-provider 
                                name="name" 
                                rules="required" 
                                v-slot="{ errors, classes }"
                            >
                                <v-select
                                    id="answers"
                                    label="title"
                                    v-model="formPayload.answers[0].is_correct_answer"
                                    :options="optionAnswer"
                                    placeholder="-- Pilih --"
                                    :reduce="(option) => option.value"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                />
                                <small 
                                    v-for="(validation, index) in validations['answers.0.is_correct_answer']" 
                                    :key="`errorName${index}`"
                                    class="text-danger">{{ validation }}</small
                                >
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <label>Answer #2:</label>
                            <validation-provider 
                                name="name" 
                                rules="required" 
                                v-slot="{ errors, classes }"
                            >
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="formPayload.answers[1].text"
                                    placeholder="Answer" 
                                />
                                <small 
                                    v-for="(validation, index) in validations['answers.0.text']" 
                                    :key="`errorName${index}`"
                                    class="text-danger">{{ validation }}</small
                                >
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <label>Is Correct Answer #2:</label>
                            <validation-provider 
                                name="name" 
                                rules="required" 
                                v-slot="{ errors, classes }"
                            >
                                <v-select
                                    id="answers"
                                    label="title"
                                    v-model="formPayload.answers[1].is_correct_answer"
                                    :options="optionAnswer"
                                    placeholder="-- Pilih --"
                                    :reduce="(option) => option.value"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                />
                                <small 
                                    v-for="(validation, index) in validations['answers.0.is_correct_answer']" 
                                    :key="`errorName${index}`"
                                    class="text-danger">{{ validation }}</small
                                >
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <label>Answer #3:</label>
                            <validation-provider 
                                name="name" 
                                rules="required" 
                                v-slot="{ errors, classes }"
                            >
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="formPayload.answers[2].text"
                                    placeholder="Answer" 
                                />
                                <small 
                                    v-for="(validation, index) in validations['answers.0.text']" 
                                    :key="`errorName${index}`"
                                    class="text-danger">{{ validation }}</small
                                >
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <label>Is Correct Answer #3:</label>
                            <validation-provider 
                                name="name" 
                                rules="required" 
                                v-slot="{ errors, classes }"
                            >
                                <v-select
                                    id="answers"
                                    label="title"
                                    v-model="formPayload.answers[2].is_correct_answer"
                                    :options="optionAnswer"
                                    placeholder="-- Pilih --"
                                    :reduce="(option) => option.value"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                />
                                <small 
                                    v-for="(validation, index) in validations['answers.0.is_correct_answer']" 
                                    :key="`errorName${index}`"
                                    class="text-danger">{{ validation }}</small
                                >
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <label>Answer #4:</label>
                            <validation-provider 
                                name="name" 
                                rules="required" 
                                v-slot="{ errors, classes }"
                            >
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="formPayload.answers[3].text"
                                    placeholder="Answer" 
                                />
                                <small 
                                    v-for="(validation, index) in validations['answers.0.text']" 
                                    :key="`errorName${index}`"
                                    class="text-danger">{{ validation }}</small
                                >
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <label>Is Correct Answer #4:</label>
                            <validation-provider 
                                name="name" 
                                rules="required" 
                                v-slot="{ errors, classes }"
                            >
                                <v-select
                                    id="answers"
                                    label="title"
                                    v-model="formPayload.answers[3].is_correct_answer"
                                    :options="optionAnswer"
                                    placeholder="-- Pilih --"
                                    :reduce="(option) => option.value"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                />
                                <small 
                                    v-for="(validation, index) in validations['answers.0.is_correct_answer']" 
                                    :key="`errorName${index}`"
                                    class="text-danger">{{ validation }}</small
                                >
                            </validation-provider>
                        </div>
                    </div>
                </div>
                <div v-if="isLoading">
                    <br />
                    <b-spinner class="mb-2" variant="primary" /><br />
                </div>
                <b-button v-if="editId == null" variant="primary" @click="createItem()" v-bind:disabled="isLoading">
                    Save Question
                </b-button>
                <b-button v-else variant="primary" @click="updateItem()" v-bind:disabled="isLoading">
                    Edit Question
                </b-button>
            </ValidationObserver>
        </b-modal>

    </div>
</template>
  
<script>
import { successNotification, errorNotification } from "@/auth/utils";
import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BSpinner,
    BFormFile,
    BDropdown,
    BDropdownItem,
    VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
import vSelect from "vue-select";
import {
    ValidationProvider,
    ValidationObserver,
    configure,
} from "vee-validate";
import { required } from "@validations";
configure({
    classes: {
        valid: "is-valid",
        invalid: "is-invalid",
        dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
        // ...
    },
});
export default {
    components: {
        BCard,
        BCol,
        BRow,
        BButton,
        BSpinner,
        BFormInput,
        BFormFile,
        BDropdown,
        BDropdownItem,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    watch: {
        filter: {
            handler: _.debounce(function () {
                this.getData();
            }, 300),
            deep: true,
        },
    },
    data() {
        return {
            required,
            currentPage: 1,
            isLoading: false,
            result: [],
            question: [],
            link: "",
            isLoadingExport: false,
            formPayload: {
                content_uuid: this.$route.params.content_uuid,
                text: "",
                type: "multiple_choice",
                order: null,
                file: null,
                answers: [
                    { 
                        text: "", 
                        is_correct_answer: null 
                    },
                    { 
                        text: "", 
                        is_correct_answer: null 
                    },
                    { 
                        text: "", 
                        is_correct_answer: null 
                    },
                    { 
                        text: "", 
                        is_correct_answer: null 
                    },
                ]
            },
            editId: null,
            contentId: null,
            elearningId: null,
            filter: {},
            validations: "",
            validationsFile: "",
            file_url: null,
            file_type: "",
            optionType: [
                { title: "Multiple Choice", value: "multiple_choice" },
                // { title: "Essay", value: "essay" },
                { title: "Task", value: "task" },
            ],
            optionAnswer: [
                { title: "Correct", value: "true" },
                { title: "Incorrect", value: "false" },
            ],
            is_task: false,
            is_essay: false,
            is_multiple_choice: false,
        };
    },
    watch: {
        'formPayload.type': function(newType) {
            this.is_task = newType === 'task';
            this.is_multiple_choice = newType === 'multiple_choice';
            // this.is_essay = newType === 'essay';
        },
    },
    setup() {
        return {
            successNotification,
            errorNotification,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        goBack() {
            this.cleanUpForm();
            this.$router.go(-1);
        },
        cleanUpForm() {
            this.editId = null;
            // this.file_url = null;
            this.formPayload = {
                content_uuid: this.$route.params.content_uuid,
                text: "",
                type: "multiple_choice",
                order: null,
                file: null,
                answers: [
                    { 
                        text: "", 
                        is_correct_answer: null 
                    },
                    { 
                        text: "", 
                        is_correct_answer: null 
                    },
                    { 
                        text: "", 
                        is_correct_answer: null 
                    },
                    { 
                        text: "", 
                        is_correct_answer: null 
                    },
                ]
            };
        },
        modalCreateQuestion() {
            this.cleanUpForm();
            this.contentId = this.$route.params.content_uuid;
            this.$bvModal.show("form-question-modal");
        },
        editItem(item) {
            this.cleanUpForm();
            this.editId = item.uuid;
            this.contentId = this.$route.params.content_uuid;
            if (item.type !== 'task') {
                this.formPayload = {
                    content_uuid: this.contentId,
                    text: item.text,
                    type: item.type,
                    order: item.order,
                    file: item.file,
                    answers: [
                        { 
                            uuid: item.answers[0].uuid, 
                            text: item.answers[0].text, 
                            is_correct_answer: item.answers[0].is_correct_answer ? 'true' : 'false', 
                        },
                        { 
                            uuid: item.answers[1].uuid, 
                            text: item.answers[1].text, 
                            is_correct_answer: item.answers[1].is_correct_answer ? 'true' : 'false', 
                        },
                        { 
                            uuid: item.answers[2].uuid, 
                            text: item.answers[2].text, 
                            is_correct_answer: item.answers[2].is_correct_answer ? 'true' : 'false', 
                        },
                        { 
                            uuid: item.answers[3].uuid, 
                            text: item.answers[3].text, 
                            is_correct_answer: item.answers[3].is_correct_answer ? 'true' : 'false', 
                        },
                    ]
                };
            } else {
                this.formPayload = {
                    content_uuid: this.contentId,
                    text: item.text,
                    type: item.type,
                    order: item.order,
                    file: item.file,
                };
                this.file_url = this.formPayload.file;
                this.file_type = this.file_url.substr(
                    this.file_url.lastIndexOf(".") + 1
                );
            }
            this.$bvModal.show("form-question-modal");
        },
        updateItem() {
            this.isLoading = true;
            this.validations = "";
            const payload = new FormData();
            payload.append("content_uuid", this.formPayload.content_uuid);
            payload.append("text", this.formPayload.text);
            payload.append("type", this.formPayload.type);
            payload.append("order", this.formPayload.order);
            payload.append("file", this.formPayload.file);
            if (this.formPayload.type !== 'task') {
                for (let i = 0; i < this.formPayload.answers.length; i++) {
                    payload.append(`answers[${i}][uuid]`, this.formPayload.answers[i].uuid);
                    payload.append(`answers[${i}][text]`, this.formPayload.answers[i].text);
                    payload.append(`answers[${i}][is_correct_answer]`, this.formPayload.answers[i].is_correct_answer);
                }
            }
            this.$http
                .post(`/api/v1/admin/e-learning-content/question/${this.editId}`, payload, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                    this.$bvModal.hide("form-question-modal");
                    this.getData();
                    successNotification(
                        this,
                        "Success",
                        "Question successfully updated!"
                    );
                    this.cleanUpForm();
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.data.meta.validations) {
                        this.validations = error.response.data.meta.validations;
                    }
                    else if (error.response.data.meta.messages) {
                        this.validationsFile = error.response.data.meta.messages;
                    }
                    this.isLoading = false;
                });
        },
        createItem() {
            this.isLoading = true;
            this.validations = "";
            const payload = new FormData();
            payload.append("content_uuid", this.formPayload.content_uuid);
            payload.append("text", this.formPayload.text);
            payload.append("type", this.formPayload.type);
            payload.append("order", this.formPayload.order);
            payload.append("file", this.formPayload.file);
            
            for (let i = 0; i < this.formPayload.answers.length; i++) {
                payload.append(`answers[${i}][text]`, this.formPayload.answers[i].text);
                payload.append(`answers[${i}][is_correct_answer]`, this.formPayload.answers[i].is_correct_answer);
            }

            this.$http
            .post("/api/v1/admin/e-learning-content/question", payload, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => {
                this.$bvModal.hide("form-question-modal");
                this.getData(this.currentPage);
                successNotification(
                this,
                "Success",
                "Question successfully created"
                );
                this.cleanUpForm();
                this.isLoading = false;

            })
            .catch((error) => {
                if (error.response.data.meta.validations) {
                    this.validations = error.response.data.meta.validations;
                }
                else if (error.response.data.meta.messages) {
                    this.validationsFile = error.response.data.meta.messages;
                }
                this.isLoading = false;
            });
        },
        changeFile(event) {
            this.formPayload.file = event.target.files[0];
            this.showFile(event.target.files[0]);
        },
        showFile(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
            this.file_url = e.target.result;
            var str = e.target.result;
            var startIndex = str.indexOf("/") + 1; // Menentukan indeks mulai setelah karakter '/'
            var endIndex = str.indexOf(";"); // Menentukan indeks akhir sebelum karakter ';'
            var imageType = str.substring(startIndex, endIndex); // Memotong string berdasarkan indeks mulai dan akhir
            this.file_type = imageType;
            };
            reader.readAsDataURL(file);
        },
        getData() {
            this.isLoading = true;
            this.elearningId = this.$route.params.elearning_uuid;
            this.contentId = this.$route.params.content_uuid;
            this.$http
                .get(`/api/v1/admin/e-learning-content/question?elearning_uuid=${this.elearningId}&content_uuid=${this.contentId}`)
                .then((response) => {
                    this.question = response.data.data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteItem(slug) {
            this.$swal({
                title: "Are you sure?",
                text: "Are you sure to delete this E-Learning Question?",
                icon: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    this.$http
                        .delete(`/api/v1/admin/e-learning-content/question/${slug}`)
                        .then((response) => {
                            this.getData(this.currentPage);
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: "E-Learning Question successfully deleted",
                                customClass: {
                                    confirmButton: "btn btn-success",
                                },
                            });
                            this.isLoading = false;
                        })
                        .catch((error) => {
                            if (error.response.data.meta.messages.length > 0) {
                                errorNotification(
                                    this,
                                    "Oops!",
                                    error.response.data.meta.messages
                                );
                            }
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.px-25{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>